@font-face {
  font-family: 'Font1';
  src: url('./assets/AvenirNextCondensed-Heavy.ttf') format('truetype'),
       url('data:application/font-woff;charset=utf-8;base64,<base64-encoded-font-data>') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Font2';
  src: url('./assets/Lexend-Regular.ttf') format('truetype'),
       url('data:application/font-woff;charset=utf-8;base64,<base64-encoded-font-data>') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Font3';
  src: url('./assets/Le.ttf') format('truetype'),
       url('data:application/font-woff;charset=utf-8;base64,<base64-encoded-font-data>') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Font4';
  src: url('./assets/an.ttf') format('truetype'),
       url('data:application/font-woff;charset=utf-8;base64,<base64-encoded-font-data>') format('woff');
  font-weight: normal;
  font-style: normal;
}

:root{
  --primary-color: #8221de;
  --secondary-color: #4278f5;
  --content-color: #4278f5;
  --text-color: black;
}




.App {
  text-align: center;
}

body { width: 100%; overflow: hidden  }

.single-display {
  overflow-y: scroll;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.background-main {
  background-position: 15vw 100%;

background: radial-gradient(circle at bottom  , var(--primary-color) 10%, var(--secondary-color) 74%);


  height: 100vh;
}

.login-box {
  margin-left: 40%;
}

.center {
  margin: auto;
  width: 50%;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.sign-in {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);

}

.button {
  top: 70%;
 
  left: 50%;

  transform: translate(-53%);
}

.text {
  color: #D9D4D3;
  font-size: 2.5em;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.container {
  max-width: 400px;
  /* margin: 10px auto; */
  /* padding: 50px 20px; */
  text-align: center;
}



.list {
  list-style: none;
  padding: 0;
  margin: 0;
  
}

.list-item {
  padding: 5px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 1px;
  cursor: grab;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.2s ease-in-out;
}

.list-item:hover {
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.2);
}

.grid {
  background-color: white;
}

/* Create gridlines using background-image */
.grid {
  background-image: linear-gradient(to right, black 1px, transparent 0.5px),
    linear-gradient(to bottom, black 1px, transparent 1px);
  background-size: 30px 30px; /* Set the spacing between gridlines */
}

.override-container {
  margin-left: 200px;
}

.upload-container {
  margin-bottom: 20px;
  margin-left: 200px;
}

.data-table {
  border-collapse: collapse;
  width: 100%;
}

.data-table th, .data-table td {
  border: 0px solid #303030;
  padding: 8px;
  text-align: left;
}

.data-table th {
  background-color: #505152;
}

.data-table tr:hover {
  background-color: #5e5d5c;
}

input {
  margin-right: 5px;
}

button {
  margin-right: 5px;
  padding: 5px 10px;
  cursor: pointer;
}


.styled-table {
  width: 100%;
  border-collapse: collapse;
  background-color: white;
  color: black;
  font-family: Arial, sans-serif;
  margin: 20px 0;
  font-size: 16px;
  text-align: left;

}

.styled-table th,
.styled-table td {
  padding: 12px 15px;
  border: 1px solid #ddd;
}

.styled-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.styled-table tbody tr:nth-of-type(even) {
  background-color: #f9f9f9;

}

.styled-table tbody tr:hover {
  background-color: #e9e9e9;
}

